import React from 'react'
import { Link } from 'react-router-dom'
import removeMd from 'remove-markdown'

const Review = ({ review }) => {
  return (
    <div key={review.id} className="review-card">
      <div className="rating">{review.attributes.rating}</div>
      <h2>{review.attributes.title}</h2>
      {review.attributes.categories.data.map((category) => (
        <span key={category.id} className="tag">
          <Link key={category.id} to={`/category/${category.id}`}>
            {category.attributes.name}
          </Link>
        </span>
      ))}
      <p>{removeMd(review.attributes.body.substring(0, 200))}...</p>
      <Link to={`/details/${review.id}`}>Read more</Link>
    </div>
  )
}

export default Review
