// capitalize a string
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// get all unique values
const getUnique = (items, value) => {
  return [...new Set(items.map((item) => item[value]))]
}

// add/update url param
const addOrUpdateUrlParam = (uri, paramKey, paramVal) => {
  var re = new RegExp('([?&])' + paramKey + '=[^&#]*', 'i')
  if (re.test(uri)) {
    uri = uri.replace(re, '$1' + paramKey + '=' + paramVal)
  } else {
    var separator = /\?/.test(uri) ? '&' : '?'
    uri = uri + separator + paramKey + '=' + paramVal
  }
  return uri
}

// get url param
const getUrlParam = (str, param) => {
  const urlParams = new URLSearchParams(str)
  return urlParams.get(param)
}

// get url params
const getUrlParams = () => {
  let params = {}
  window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (key, value) => {
    params[key] = value
  })
  return params
}

// scroll to top
function scrollToTop() {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' })
}

// toggle password
function togglePassword(passwordField, eyeIcon) {
  if (passwordField.type === 'password') passwordField.type = 'text'
  else passwordField.type = 'password'
  eyeIcon.classList.toggle('shown')
}

// paging
const paging = (c, m) => {
  let current = c,
    last = m,
    delta = 1, // 2
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    l

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i)
    }
  }

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}

export {
  capitalize,
  getUnique,
  addOrUpdateUrlParam,
  getUrlParam,
  getUrlParams,
  scrollToTop,
  togglePassword,
  paging
}
