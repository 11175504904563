import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import spinner from '../spinner.svg'
import Pagination from '../components/Pagination'
import Review from '../components/Review'

const REVIEWS = gql`
  query GetReviews($page: Int!, $pageSize: Int!) {
    reviews(pagination: { page: $page, pageSize: $pageSize }) {
      data {
        id
        attributes {
          title
          body
          rating
          categories {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`

const CATEGORIES = gql`
  query GetCategories {
    categories {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

const Homepage = ({ page, setPage }) => {
  const [total, setTotal] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [pageSize] = useState(2)

  const reviewsSet = useQuery(REVIEWS, {
    variables: { page, pageSize }
  })

  const categoriesSet = useQuery(CATEGORIES)

  useEffect(() => {
    if (reviewsSet.data && reviewsSet.data.reviews.meta.pagination) {
      setPageCount(reviewsSet.data.reviews.meta.pagination.pageCount)
      setTotal(reviewsSet.data.reviews.meta.pagination.total)
    }
  }, [reviewsSet.data, categoriesSet.data])

  if (reviewsSet.loading || categoriesSet.loading)
    return <img src={spinner} className="spinner" alt="loading" />
  if (reviewsSet.error || categoriesSet.error)
    return <p className="text-center">Error!</p>

  return reviewsSet.data.reviews.data.length > 0 ? (
    <>
      <div className="flex-container">
        <div className="first-col">
          <nav className="filter-reviews fw-light">
            <span>Filter reviews by category:</span>
            {categoriesSet.data.categories.data.map((category) => (
              <Link key={category.id} to={`/category/${category.id}`}>
                {category.attributes.name}
              </Link>
            ))}
          </nav>
        </div>
        <div className="fw-light">
          <span className="fw-semibold">{total}</span> Review
          {`${total !== 1 ? 's' : ''}`}
        </div>
      </div>
      {reviewsSet.data.reviews.data.map((review) => (
        <Review review={review} key={review.id} />
      ))}
      <div className="show-desktop">
        <Pagination page={page} setPage={setPage} pageCount={pageCount} />
      </div>
      <div className="show-mobile">
        <Pagination
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          theme="carrier"
        />
      </div>
    </>
  ) : (
    <p className="text-center">No reviews</p>
  )
}

export default Homepage
