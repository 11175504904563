import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { UserContext } from '../contexts/UserContext'

const SiteHeader = ({ setPage }) => {
  const { user, setUser } = useContext(UserContext)

  const navigate = useNavigate()

  const handleLogout = async (e) => {
    e.preventDefault()
    await setUser(null)
    localStorage.removeItem('myReviewsToken')
    navigate('/login', { replace: true })
  }

  return (
    <div className="site-header">
      <div className="logo">
        <Link to="/" onClick={() => setPage(1)}>
          <h1>My Reviews</h1>
        </Link>
      </div>
      <div className="menu">
        {!user ? (
          <Link to="/login">Login</Link>
        ) : (
          <div className="dropdown">
            <span className="fw-light ml-2 dropdown-icon">
              Hi, {user.username}
            </span>
            <div className="dropdown-content">
              <ul>
                <li>
                  <Link to="/review/add" className="text-primary link-dotted">
                    + Add Review
                  </Link>
                </li>
                <li>
                  <a href="#!" onClick={handleLogout}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SiteHeader
