import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { gql, useApolloClient } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// contexts
import { UserContext } from '../contexts/UserContext'

// utils
import { togglePassword } from '../utils'

// assets
import spinner from '../spinner.svg'

const REGISTER = gql`
  mutation Register($username: String!, $email: String!, $password: String!) {
    register(
      input: { username: $username, email: $email, password: $password }
    ) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`

const Register = () => {
  const [token, setToken] = useState(null)
  const [error, setError] = useState('')
  const { user, setUser, loading } = useContext(UserContext)

  const client = useApolloClient()
  const navigate = useNavigate()

  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.getElementsByTagName('input')[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('username is required'),
    email: Yup.string().email('Email not valid').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be 6 characters or longer')
      .required('Password is required'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    )
  })

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async (values, { resetForm, setErrors, setSubmitting }) => {
      resetForm({ values: { ...values, password: '', confirmPassword: '' } })
      try {
        const data = await client.mutate({
          mutation: REGISTER,
          variables: {
            username: values.username,
            email: values.email,
            password: values.password
          }
        })

        await setUser(data.data.register.user)
        setToken(data.data.register.jwt)
        resetForm()
        navigate('/')
      } catch (error) {
        setError(error.message)
      }
      setSubmitting(false)
    }
  })

  useEffect(() => {
    if (token) localStorage.setItem('myReviewsToken', token)
  }, [token])

  if (loading) return <img src={spinner} className="spinner" alt="loading" />

  // if (user) return <Navigate to="/" />

  return (
    <>
      {error && (
        <div className="custom-card-top custom-card-small alert-danger">
          {error}
        </div>
      )}
      <div className="custom-card custom-card-small">
        <div className="custom-card-title">Register</div>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className="mb-5">
            <label htmlFor="username">
              Username <small>(required)</small>
            </label>
            <input
              type="text"
              className={
                formik.touched.username && formik.errors.username
                  ? 'invalid'
                  : 'null'
              }
              name="username"
              id="username"
              onChange={formik.handleChange}
              value={formik.values.username}
            />
            <small className="invalid-feedback">
              {formik.touched.username && formik.errors.username
                ? formik.errors.username
                : null}
            </small>
          </div>
          <div className="mb-5">
            <label htmlFor="email">
              Email <small>(required)</small>
            </label>
            <input
              type="email"
              className={
                formik.touched.email && formik.errors.email ? 'invalid' : 'null'
              }
              name="email"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <small className="invalid-feedback">
              {formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null}
            </small>
          </div>
          <div className="mb-5">
            <label htmlFor="password">
              Password <small>(required)</small>
            </label>
            <div className="hide-show-password">
              <input
                type="password"
                className={
                  formik.touched.password && formik.errors.password
                    ? 'invalid'
                    : 'null'
                }
                name="password"
                id="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <small className="invalid-feedback">
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null}
              </small>
              <span
                className="eye-icon"
                onClick={handleShowHidePassword}
              ></span>
            </div>
          </div>
          <div className="mb-5">
            <label htmlFor="confirmPassword">
              Confirm Password <small>(required)</small>
            </label>
            <div className="hide-show-password">
              <input
                type="password"
                className={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? 'invalid'
                    : 'null'
                }
                name="confirmPassword"
                id="confirmPassword"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
              />
              <small className="invalid-feedback">
                {formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? formik.errors.confirmPassword
                  : null}
              </small>
              <span
                className="eye-icon"
                onClick={handleShowHidePassword}
              ></span>
            </div>
          </div>
          <button type="submit" className="btn">
            Register
          </button>
        </form>
      </div>
      <div className="custom-card-bottom custom-card-small">
        Already have an account? <Link to="/login">Login</Link>
      </div>
    </>
  )
}

export default Register
