import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import { gql, useApolloClient } from '@apollo/client'

// contexts
import { UserContext } from '../contexts/UserContext'

// utils
import { togglePassword } from '../utils'

// assets
import spinner from '../spinner.svg'

const LOGIN = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`

const Login = () => {
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [token, setToken] = useState(null)

  const { user, setUser, loading } = useContext(UserContext)

  const client = useApolloClient()
  const navigate = useNavigate()

  const handleShowHidePassword = (e) => {
    const passwordField =
      e.currentTarget.parentElement.getElementsByTagName('input')[0]
    const eyeIcon = e.currentTarget
    togglePassword(passwordField, eyeIcon)
  }

  const handleLogin = async (e) => {
    e.preventDefault()

    try {
      const data = await client.mutate({
        mutation: LOGIN,
        variables: { identifier, password }
      })

      await setUser(data.data.login.user)
      setToken(data.data.login.jwt)
      setError('')
      navigate('/')
    } catch (error) {
      const errorMsg =
        error.message === '2 errors occurred'
          ? 'Please enter your email or username, and password.'
          : 'Invalid identifier or password'
          ? 'Incorrect username, email or password.'
          : error.message
      setError(errorMsg)
    }
  }

  useEffect(() => {
    if (token) localStorage.setItem('myReviewsToken', token)
  }, [token])

  if (loading) return <img src={spinner} className="spinner" alt="loading" />

  // if (user) return <Navigate to="/" />

  return (
    <>
      {error && (
        <div className="custom-card-top custom-card-small alert-danger">
          {error}
        </div>
      )}
      <div className="custom-card custom-card-small">
        <div className="custom-card-title">Login</div>
        <form noValidate>
          <div className="mb-5">
            <label htmlFor="identifier">Email / Username</label>
            <input
              type="text"
              name="identifier"
              id="identifier"
              onChange={(e) => setIdentifier(e.target.value)}
              value={identifier}
            />
          </div>
          <div className="mb-5">
            <label htmlFor="password">Password</label>
            <div className="hide-show-password">
              <input
                type="password"
                name="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              <span
                className="eye-icon"
                onClick={handleShowHidePassword}
              ></span>
            </div>
          </div>
          <div className="mb-5">
            <label className="customcheck customcheck-sm">
              Remember me
              <input type="checkbox" />
              <span className="checkmark"></span>
            </label>
          </div>
          <button type="submit" className="btn" onClick={handleLogin}>
            Login
          </button>
        </form>
      </div>
      <div className="custom-card-bottom custom-card-small">
        Need an account? <Link to="/register">Register</Link>
      </div>
    </>
  )
}

export default Login
