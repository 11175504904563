import React, { useEffect, useContext } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { gql, useQuery, useApolloClient } from '@apollo/client'
import Markdown from 'react-markdown'

// contexts
import { UserContext } from '../contexts/UserContext'

// assets
import spinner from '../spinner.svg'

const REVIEW = gql`
  query GetReview($id: ID!) {
    review(id: $id) {
      data {
        id
        attributes {
          title
          body
          rating
          categories {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`

const DELETE_REVIEW = gql`
  mutation DeleteReview($id: ID!) {
    deleteReview(id: $id) {
      data {
        id
        attributes {
          title
        }
      }
    }
  }
`

const ReviewDetails = ({ setPage }) => {
  const { id } = useParams()

  const { user, loading } = useContext(UserContext)

  const client = useApolloClient()
  const navigate = useNavigate()

  const {
    loading: loadingReviewDetail,
    error,
    data
  } = useQuery(REVIEW, {
    variables: { id }
  })

  const handleDeleteReview = async () => {
    try {
      const token = localStorage.getItem('myReviewsToken')
      const data = await client.mutate({
        mutation: DELETE_REVIEW,
        variables: { id },
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      })
      await setPage(1)
      navigate('/')
    } catch (error) {}
  }

  useEffect(() => {}, [])

  if (loadingReviewDetail || loading)
    return <img src={spinner} className="spinner" alt="loading" />
  if (error) return <p className="text-center">Error!</p>

  return (
    <div className="review-card">
      <div className="rating">{data.review.data.attributes.rating}</div>
      <h2>{data.review.data.attributes.title}</h2>
      {data.review.data.attributes.categories.data.map((category) => (
        <span key={category.id} className="tag">
          <Link key={category.id} to={`/category/${category.id}`}>
            {category.attributes.name}
          </Link>
        </span>
      ))}
      <Markdown children={data.review.data.attributes.body} />
      {user && (
        <div className="text-end mt-6">
          <Link to={`/review/edit/${id}`}>Edit</Link>
          <Link
            to="#"
            className="ml-2"
            onClick={() => {
              if (
                window.confirm(`Are you sure you wish to delete this review?`)
              )
                handleDeleteReview()
            }}
          >
            Delete
          </Link>
        </div>
      )}
    </div>
  )
}

export default ReviewDetails
