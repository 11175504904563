import { useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

// contexts
import { UserContext } from './contexts/UserContext'

// hooks
import useFindUser from './hooks/useFindUser'

// page & layout imports
import Homepage from './pages/Homepage'
import ReviewDetails from './pages/ReviewDetails'
import Category from './pages/Category'
import Login from './pages/Login'
import Register from './pages/Register'
import SiteHeader from './components/SiteHeader'
import SiteFooter from './components/SiteFooter'
import AddReview from './pages/AddReview'
import EditReview from './pages/EditReview'

// apollo client
const client = new ApolloClient({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
  cache: new InMemoryCache()
})

const App = () => {
  const [page, setPage] = useState(1)

  const token = localStorage.getItem('myReviewsToken')
    ? localStorage.getItem('myReviewsToken')
    : ''
  const { user, setUser, loading } = useFindUser(client, token)

  return (
    <Router>
      <UserContext.Provider value={{ user, setUser, loading }}>
        <ApolloProvider client={client}>
          <div className="App">
            <div className="container-vertical">
              <div className="container-vertical-content">
                <SiteHeader setPage={setPage} />
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<Homepage page={page} setPage={setPage} />}
                  />
                  <Route
                    path="/details/:id"
                    element={<ReviewDetails setPage={setPage} />}
                  />
                  <Route path="/category/:id" element={<Category />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/review/add" element={<AddReview />} />
                  <Route path="/review/edit/:id" element={<EditReview />} />
                </Routes>
              </div>
            </div>
            <SiteFooter />
          </div>
        </ApolloProvider>
      </UserContext.Provider>
    </Router>
  )
}

export default App
