import React from 'react'

const SiteFooter = () => {
  return (
    <div className="site-footer">
      <p className="mb-5">Youcef Developer &copy; 2021</p>
      <p className="mb-2">Technologies</p>
      <span className="badge">strapi</span>
      <span className="badge">react</span>
      <span className="badge">graphql</span>
      <span className="badge">postgresql</span>
      <span className="badge">heroku</span>
      <span className="badge">vercel</span>
    </div>
  )
}

export default SiteFooter
