import { useState, useEffect } from 'react'
import { gql } from '@apollo/client'

const USER = gql`
  query User {
    me {
      id
      username
      email
    }
  }
`

const useFindUser = (client, token) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const findUser = async () => {
      setLoading(true)

      try {
        const data = await client.query({
          query: USER,
          context: {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        })

        setUser(data.data.me)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    findUser()
  }, [client, token])

  return { user, setUser, loading }
}

export default useFindUser
