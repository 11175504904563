import React from 'react'

import { paging } from '../utils'

const Pagination = ({ page, setPage, pageCount, theme }) => {
  return (
    pageCount > 1 && (
      <>
        {theme === 'default' ? (
          <ul className="pagination">
            <li className={`${page === 1 ? 'disabled' : ''}`}>
              <a
                href={`#${page}`}
                onClick={() => setPage(page - 1)}
                title="Previous"
              >
                &#8249;
              </a>
            </li>
            {/* previous */}

            {paging(page, pageCount).map((p) => (
              <li
                className={`${
                  p === '...' ? 'dotted disabled' : p === page ? 'active' : ''
                }`}
                key={p}
              >
                <a href={`#${p}`} onClick={() => setPage(p)}>
                  {p}
                </a>
              </li>
            ))}

            <li className={`${page === pageCount ? 'disabled' : ''}`}>
              <a
                href={`#${page}`}
                onClick={() => setPage(page + 1)}
                title="Next"
              >
                &#8250;
              </a>
            </li>
            {/* next */}
          </ul>
        ) : (
          <div className="paging">
            <div className="paging-carrier">
              <div>
                {page === 1 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ccc"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="arcs"
                  >
                    <path d="M15 18l-6-6 6-6"></path>
                  </svg>
                ) : (
                  <a
                    title="Previous Page"
                    href={`#${page}`}
                    onClick={() => setPage(page - 1)}
                    className="disabled"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="arcs"
                    >
                      <path d="M15 18l-6-6 6-6"></path>
                    </svg>
                  </a>
                )}
              </div>
              <span>
                <span className="fw-lighter">{page}</span> / {pageCount}
              </span>
              <div>
                {page === pageCount ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ccc"
                    strokeWidth="1"
                    strokeLinecap="round"
                    strokeLinejoin="arcs"
                  >
                    <path d="M9 18l6-6-6-6"></path>
                  </svg>
                ) : (
                  <a
                    title="Next Page"
                    href={`#${page}`}
                    onClick={() => setPage(page + 1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#333"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="arcs"
                    >
                      <path d="M9 18l6-6-6-6"></path>
                    </svg>
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    )
  )
}

Pagination.defaultProps = {
  theme: 'default'
}

export default Pagination
