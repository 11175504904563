import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

import spinner from '../spinner.svg'
import Review from '../components/Review'
import Pagination from '../components/Pagination'

const CATEGORY = gql`
  query GetCategory($id: ID!) {
    category(id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

const REVIEWS_BY_CATEGORY = gql`
  query ReviewsByCategory($id: ID!, $page: Int!, $pageSize: Int!) {
    reviews(
      filters: { categories: { id: { eq: $id } } }
      pagination: { page: $page, pageSize: $pageSize }
    ) {
      data {
        id
        attributes {
          title
          body
          rating
          categories {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          page
          pageSize
          pageCount
        }
      }
    }
  }
`

const Category = () => {
  const { id } = useParams()
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [pageSize] = useState(2)

  const categorySet = useQuery(CATEGORY, {
    variables: { id }
  })

  const reviewsByCategorySet = useQuery(REVIEWS_BY_CATEGORY, {
    variables: { id, page, pageSize }
  })

  useEffect(() => {
    if (
      reviewsByCategorySet.data &&
      reviewsByCategorySet.data.reviews.meta.pagination
    ) {
      setTotal(reviewsByCategorySet.data.reviews.meta.pagination.total)
      setPage(reviewsByCategorySet.data.reviews.meta.pagination.page)
      setPageCount(reviewsByCategorySet.data.reviews.meta.pagination.pageCount)
      setTotal(reviewsByCategorySet.data.reviews.meta.pagination.total)
    }
  }, [reviewsByCategorySet.data, categorySet.data])

  if (reviewsByCategorySet.loading)
    return <img src={spinner} className="spinner" alt="loading" />
  if (reviewsByCategorySet.error) return <p className="text-center">Error!</p>

  return total > 0 ? (
    <>
      <div className="flex-container">
        <div className="first-col">
          <div className="fw-light">
            Reviews filtered by{' '}
            <span className="text-primary fw-semibold">
              {categorySet.data.category.data.attributes.name}
            </span>
          </div>
        </div>
        <div className="fw-light">
          <span className="fw-semibold">{total}</span> Review
          {`${total !== 1 ? 's' : ''}`}
        </div>
      </div>
      {reviewsByCategorySet.data.reviews.data.map((review) => (
        <Review review={review} key={review.id} />
      ))}
      <div className="show-desktop">
        <Pagination page={page} setPage={setPage} pageCount={pageCount} />
      </div>
      <div className="show-mobile">
        <Pagination
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          theme="carrier"
        />
      </div>
    </>
  ) : (
    <p className="text-center">
      No reviews filtered by{' '}
      <span className="text-primary fw-semibold">
        {categorySet.data.category.data.attributes.name}
      </span>
    </p>
  )
}

export default Category
