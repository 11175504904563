import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'
import { gql, useApolloClient } from '@apollo/client'
import SimpleMdeReact from 'react-simplemde-editor'
import 'easymde/dist/easymde.min.css'

// contexts
import { UserContext } from '../contexts/UserContext'

// assets
import spinner from '../spinner.svg'

const CATEGORIES = gql`
  query Categories {
    categories {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

const REVIEW = gql`
  query Review($id: ID!) {
    review(id: $id) {
      data {
        id
        attributes {
          title
          rating
          body
          categories {
            data {
              id
            }
          }
          author {
            data {
              id
            }
          }
        }
      }
    }
  }
`

const UPDATE_REVIEW = gql`
  mutation UpdateReview(
    $id: ID!
    $title: String!
    $rating: Int!
    $body: String!
    $categories: [ID]!
    $author: ID!
  ) {
    updateReview(
      id: $id
      data: {
        title: $title
        rating: $rating
        body: $body
        categories: $categories
        author: $author
      }
    ) {
      data {
        id
        attributes {
          title
          categories {
            data {
              id
              attributes {
                name
              }
            }
          }
          author {
            data {
              id
              attributes {
                username
              }
            }
          }
        }
      }
    }
  }
`

const EditReview = () => {
  const { id } = useParams()
  const client = useApolloClient()
  const navigate = useNavigate()

  const { user, loading } = useContext(UserContext)

  const [categories, setCategories] = useState(null)
  const [loadingCategory, setLoadingCategory] = useState(true)
  const [formData, setFormData] = useState({
    title: '',
    rating: '',
    body: '',
    categories: [],
    author: ''
  })
  const [loadingReview, setLoadingReview] = useState(true)
  const [reviewError, setReviewError] = useState('')
  const [errors, setErrors] = useState({
    title: false,
    rating: false,
    body: false
  })

  const handleCategory = (e) => {
    if (e.target.checked) {
      setFormData({
        ...formData,
        categories: [...formData.categories, e.target.value]
      })
    } else {
      setFormData({
        ...formData,
        categories: formData.categories.filter(
          (category) => category !== e.target.value
        )
      })
    }
  }

  const onRatingChange = (e) => {
    const rating = e.target.value
    if (!rating || rating.match(/^([1-9]|10)$/)) {
      setFormData({ ...formData, rating })
    }
  }

  const onChangeBody = (value) => {
    setFormData({ ...formData, body: value })
  }

  const handleEditReview = async (e) => {
    e.preventDefault()

    formData.title.trim()
      ? setErrors((prevState) => ({ ...prevState, title: false }))
      : setErrors((prevState) => ({ ...prevState, title: true }))

    formData.rating
      ? setErrors((prevState) => ({ ...prevState, rating: false }))
      : setErrors((prevState) => ({ ...prevState, rating: true }))

    formData.body.trim()
      ? setErrors((prevState) => ({ ...prevState, body: false }))
      : setErrors((prevState) => ({ ...prevState, body: true }))

    if (formData.title.trim() && formData.rating && formData.body.trim()) {
      try {
        const token = localStorage.getItem('myReviewsToken')
        const data = await client.mutate({
          mutation: UPDATE_REVIEW,
          variables: {
            id,
            title: formData.title,
            rating: Number(formData.rating),
            body: formData.body,
            categories: formData.categories,
            author: formData.author
          },
          context: {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        })

        navigate(`/details/${id}`)
      } catch (error) {
        setReviewError(error.message)
      }
    }
  }

  useEffect(async () => {
    setLoadingCategory(true)

    const fetchCategoriesData = async () => {
      try {
        const data = await client.query({
          query: CATEGORIES
        })

        setCategories(data)
        setLoadingCategory(false)
      } catch (error) {
        setLoadingCategory(false)
      }
    }

    fetchCategoriesData()

    setLoadingReview(true)
    try {
      const token = localStorage.getItem('myReviewsToken')
      const data = await client.query({
        query: REVIEW,
        variables: {
          id
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      })

      const attrData = data.data.review.data.attributes
      const categories = attrData.categories.data.map((cat) => cat.id)
      const author = attrData.author.data.id

      setFormData({
        ...formData,
        title: attrData.title,
        rating: attrData.rating,
        body: attrData.body,
        categories,
        author
      })
      setLoadingReview(false)
    } catch (error) {
      setLoadingReview(false)
    }
  }, [])

  if (loadingCategory || loadingReview || loading)
    return <img src={spinner} className="spinner" alt="loading" />

  if (!user) return <Navigate to="/login" />

  return (
    <>
      {reviewError && (
        <div className="custom-card-top alert-danger">{reviewError}</div>
      )}
      <div className="custom-card">
        <div className="custom-card-title">Edit Review</div>
        <form noValidate>
          <div className="mb-5">
            <label htmlFor="title">
              Title <small>(required)</small>
            </label>
            <input
              type="text"
              name="title"
              id="title"
              className={`${errors.title && 'invalid'}`}
              value={formData.title}
              onChange={(e) => {
                setFormData({ ...formData, title: e.target.value })
              }}
            />
            {errors.title && (
              <small className="small fw-light invalid-feedback">
                The title field is required
              </small>
            )}
          </div>
          <div className="mb-5">
            <label htmlFor="rating">
              Rating <small>(required)</small>
            </label>
            <input
              type="text"
              name="rating"
              id="rating"
              className={`${errors.rating && 'invalid'}`}
              value={formData.rating}
              onChange={onRatingChange}
            />
            <small className="fw-light text-muted">
              The rating needs to be between 1 and 10
            </small>
            {errors.rating && (
              <div className="small fw-light invalid-feedback">
                The rating field is required
              </div>
            )}
          </div>
          <div className="mb-5">
            <label htmlFor="body">
              Body <small>(required)</small>
            </label>
            <SimpleMdeReact value={formData.body} onChange={onChangeBody} />
            {errors.body && (
              <small className="small fw-light invalid-feedback">
                The body field is required
              </small>
            )}
          </div>
          <div className="mb-5">
            <label htmlFor="category">
              Categories <small>(required)</small>
            </label>
            <div className="mb-5">
              {categories.data.categories.data.map((catagory) => (
                <label
                  className="customcheck customcheck-sm mr-5"
                  key={catagory.id}
                >
                  {catagory.attributes.name}
                  <input
                    type="checkbox"
                    name={`category_${catagory.id}`}
                    id={`category_${catagory.id}`}
                    value={catagory.id}
                    onChange={handleCategory}
                    defaultChecked={
                      formData.categories.includes(catagory.id) ? true : false
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              ))}
            </div>
          </div>
          <button type="submit" className="btn" onClick={handleEditReview}>
            Update Review
          </button>
        </form>
      </div>
    </>
  )
}

export default EditReview
